/** @format */

.Modal {
  position: fixed;
  z-index: 200;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  background-color: white;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  top: 5%;
  left: 5%;
  width: 90%;
  transition: all 0.3s ease-out;
}

@media (min-width: 55rem) {
  .Modal {
    position: fixed;
    z-index: 200;
    border: 1px solid #eee;
    box-shadow: 0 2px 2px #ccc;
    background-color: white;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    top: 10%;
    left: 25%;
    width: 50%;
    transition: all 0.3s ease-out;
  }
}

.body-modal-open {
  overflow-y: hidden;
}

.Modal hr {
  border-top: 2px solid #bbb;
  border-radius: 5px;
}

.ModalOpen {
  animation: openModal 0.4s ease-out forwards;
}

.ModalClosed {
  animation: closeModal 1s ease-out forwards;
}

.ModalHeader {
  padding: 0.5rem 1rem;
  background-color: rgb(103, 187, 255);
  color: white;
  display: flex;
  justify-content: space-between;
}

.ModalHeader h2 {
  margin: 0;
  font-size: 1.2rem;
  padding: 0.3rem;
  color: black;
}

.ModalHeader button {
  color: black;
  font-size: 28px;
  font-weight: bold;
  background-color: transparent;
  border: 0;
}

.modal-body {
  overflow-y: auto;
  max-height: 60vh;
}

.footer {
  padding: 0.5rem 1rem;
  background-color: white;
  color: black;
  display: flex;
  justify-content: flex-end;
}

.footer button {
  padding: 0.4rem 0.6rem;
  border: 2px solid;
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
}

.footer button.success {
  border-color: #04aa6d;
  color: green;
}

.footer button.success:hover,
.footer button.success:focus {
  background-color: #04aa6d;
  color: white;
}

.ModalHeader button:hover,
.ModalHeader button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.fade-slide-enter {
}

.fade-slide-enter-active {
  animation: openModal 0.4s ease-out forwards;
}

.fade-slide-exit {
}

.fade-slide-exit-active {
  animation: closeModal 1s ease-out forwards;
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 1;
    transform: translateY(90%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    transform: translateY(60%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
