/** @format */

.main-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Roboto', sans-serif; */
}
