/** @format */

.product-item-container {
  border: 1px solid #a6a6a6;
  background-color: white;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0.2rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px,
    rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px,
    rgb(0 0 0 / 9%) 0px -3px 5px;
}

.image-container img {
  max-width: 90%;
}

.product-description-container {
  font-weight: bold;
}

.product-description-container h3 {
  margin: 0.5rem 0;
}

.product-description {
  font-weight: normal;
  font-size: 0.8rem;
}

.product-stock-quantity {
  font-weight: normal;
  font-size: 1rem;
}

@media (min-width: 55rem) {
  .product-item-container {
    grid-template-columns: 50% 50%;
    grid-gap: 0.2rem;
  }

  .image-container img {
    max-width: 80%;
  }
}
