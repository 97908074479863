/** @format */
.container {
  background-color: #cbcbcb;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  padding: 1rem;
  margin-bottom: 1rem;
}

.order-area {
  padding: 1rem;
  width: 100%;
}

.order-area hr {
  border-top: 2px solid #bbb;
  border-radius: 5px;
}

.order-area-title {
  text-align: center;
}

.payment-area {
  padding: 1rem;
  width: 100%;
}

.payment-area-title {
  text-align: center;
}

.payment-area hr {
  border-top: 2px solid #bbb;
  border-radius: 5px;
}

.payment-area-values {
  display: grid;
  grid-template-columns: 70% 30%;
}

.payment-area-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}

@media (min-width: 55rem) {
  .container {
    background-color: #cbcbcb;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 45% 45%;
    width: 80%;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .order-area {
    padding: 1rem;
  }

  .payment-area {
    padding: 1rem;
  }

  .payment-area-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
  }

  .order-area-title {
    text-align: left;
  }

  .payment-area-title {
    text-align: right;
  }
}
